














































import { Component, Vue } from 'vue-property-decorator';
import { dispatchCreateTipoLocalidade, dispatchGetTiposLocalidade } from '@/store/tipo_localidade/actions';
import { ITipoLocalidadeCreate } from '@/interfaces/tipo_localidade';

@Component
export default class CadastrarTipoLocalidade extends Vue {
  public valid = false;
  public descricao: string = '';
  public sigla: string = '';

  public async mounted() {
    await dispatchGetTiposLocalidade(this.$store);
    this.reset();
  }

  public reset() {
    this.descricao = '';
    this.sigla = '';
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit() {
    if (await this.$validator.validateAll()) {
      const novoTipoLocalidade: ITipoLocalidadeCreate = {
        descricao: this.descricao,
        sigla: this.sigla,
      };
      const cadastrou = await dispatchCreateTipoLocalidade(this.$store, novoTipoLocalidade);
      if (cadastrou) {
        this.$router.push('/main/tiposLocalidade/listar');
      }
    }
  }
}
